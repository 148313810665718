export default {
  views: {
    list: {
      headline: {
        open: "Odprti projekti",
        closed: "Zaključeni projekti"
      },
      empty_list_notice: {
        none: "Ni odprtih projektov",
        closed: "Vsi projekti so zaključeni"
      },
      card: {
        button_text: {
          else: "Odpri projekt"
        },
        created_at: "Ustvarjeno",
        updated_at: "Zadnja sprememba",
        finished_at: "Zaključeno",
        hiring_manager: "Vodja zaposlovanja",
        action_needed: "Potrebno ukrepanje",
        company: "Podjetje"
      },
      header: {
        title: "Projekti",
        search_placeholder: "Iskanje ..."
      },
      table: {
        title: "Naziv",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        options: "Možnosti",
        status: "Stanje"
      }
    },
    edit: {
      back_button: "Na listo projektov",
      tabs: {
        contract: "Pogodba",
        adm: "ADM",
        ad: "Oglas",
        candidates: "Kandidati",
        micro: "Mikro okolje",
        overview: "Pregled"
      },
      basic: {
        company: "Podjetje",
        hiring_manager: "Vodja zaposlovanja",
        project_status: "Stanje",
        finished: "Datum zaključka"
      },
      overview: {
        funnel: {
          headline: "Lijak",
          list_description: {
            applied: "Identificirani kandidati",
            interesting: "Kandidati, ki jih smatramo, kot potencialno zanimive.",
            invited: "Kandidati, ki so bili povabljeni na razgovor.",
            testing: "Testirani kandidati.",
            proposed: "Kandidati, ki so bili predlagani naročniku.",
            confirmed: "Potrjeni kandidati.",
            selected: "Izbran kandidat"
          }
        },
        status_widget: {
          header: {
            project_status: "Stanje projekta",
            finished: "Competo",
            approved: "Potrditev naročnika"
          },
          statuses: {
            adm: "ADM",
            ad: "Oglas",
            candidates_long_list: "Poslan nabor kandidatov",
            notify: "Obvesti naročnika o predlaganih kandidatih",
            selected_candidates: "Kandidat izbran",
            confirmed_candidates: "Kandidati potrjeni"
          }
        },
        time_to_delivery: "Predviden rok predstavitve kandidatov:",
        time_to_delivery_unavailable: "podatek ni na voljo",
        hiring_managers: {
          title: "Vodje zaposlovanja",
          empty_list: "Lista vodij zaposlovanja je prazna"
        }
      },
      adm: {
        competence_title: "projekta",
        hidden: {
          text1: "Vsebina trenutno ni na voljo.",
          text2: "Za več informacij se obrnite na Competo."
        },
        locked: {
          text1: "Vsebina je trenutno zaklenjena.",
          text2: "Lahko jo vidite, vendar je ne morete urejati."
        },
        confirm_adm: "Potrdi ADM",
        notify: {
          confirm: {
            success: "ADM je bila potrjena.",
            error: "Pri potrjevanju ADM je prišlo do napake."
          }
        },
        send_email_popup: {
          label: "Pošlji sporočilo",
          title: "Pošlji sporočilo Competu",
          message: "V spodnje polje vpišite sporočilo. To bo preko elektronskega naslova poslano odgovorni osebi na tem projektu.",
          cancel: "Prekliči",
          confirm: "Pošlji",
          notify: {
            success: "Sporočilo je bilo poslano.",
            error: "Pri pošiljanju sporočila je prišlo do napake."
          }
        },
        competences_widget: {
          title: "Splošne kompetence",
          edit: "Uredi",
          use_competences: "Uporabi iz analize"
        },
        report_widget: {
          title: "Kompetence",
          doesnt_exist: {
            text1: "Seznam kompetenc ne obstaja",
            text2: "Za več informacij se obrnite na Competo."
          },
          buttons: {
            confirm: "Potrdi ADM",
            create_template: "Ustvari seznam",
            save_template: "Shrani predlogo",
            save: "Shrani",
            new_competence: "Nova kompetenca",
            new_competence_input: {
              placeholder: "Tukaj vpišite novo kompetenco"
            }
          }
        },
        competences_drawer: {
          title: "Urejanje kompetenc",
          search: "Iskanje ...",
          tag: "Število kompetenc: ",
          table: {
            title: "Naziv"
          },
          cancel_button: "Prekliči",
          save_button: "Shrani"
        },
        analysis_widget: {
          title: "Analiza",
          description: "Opis",
          experience: "Izkušnje in spretnosti",
          buttons: {
            save: "Shrani",
            new_competence_input: {
              placeholder: "Tukaj vpišite novo kompetenco"
            }
          },
          notify: {
            form: {
              success: "Forma je bila shranjena.",
              error: "Pri shranjevanju forme je prišlo do napake."
            },
            micro: {
              success: "Mikro okolje je bilo posodobljeno.",
              error: "Pri posodabljanju mikro okolja je prišlo do napake."
            },
            removed_competence: {
              "1": "Kompetenca ",
              "2": " je bila odstranjena. Izberete lahko največ 6 kompetenc."
            }
          },
          doesnt_exist: {
            text1: "Analiza še ni pripravljena.",
            text2: "Za več informacij se obrnite na Competo."
          },
          headlines: {
            education: "Izobrazba",
            experience: "Zahteve delovnega mesta",
            language: "Znanje tujih jezikov"
          },
          fields: {
            headlines: {
              job: "Delovno mesto",
              employment: "Zaposlitev"
            },
            company: {
              headline: "ANALIZA PODJETJA (DELOVNEGA OKOLJA)",
              vision: "Vizija in strategija podjetja ali oddelka",
              values: "Vrednote podjetja",
              milestones: "Ključni mejniki v zgodovini podjetja, ki vplivajo na vašo kulturo",
              activities: "Aktivnosti za dobro počutje zaposlenih (npr. sadna malica, športne aktivnosti, druženja, teambuildingi…)",
              advantages: "Glavne konkurenčne prednosti vašega podjetja",
              adjectives: "Trije pridevniki, s katerimi bi opisali vaše podjetje",
              advice: "Vaš prvi nasvet novemu sodelavcu"
            },
            job: {
              headline: "ANALIZA DELOVNEGA MESTA",
              title: "Naziv delovnega mesta",
              purpose: "Namen delovnega mesta",
              responsibilities: "Odgovornosti (3 ključne), povezane s ključnimi cilji",
              tasks: {
                label: "Delovne naloge (najmanj 5, največ 8 najpomembnejših)",
                placeholder: "Razvrstite po področjih, npr.: upravljanje prodaje, sodelovanje z distributerji, zagotavljanje kakovosti ipd."
              },
              micro: {
                label: "Mikro organizacija",
                persons: {
                  direct: "Koliko oseb bo vodil neposredno?",
                  indirect: "Koliko oseb bo vodil posredno?"
                },
                report: {
                  direct: "Komu bo poročal neposredno?",
                  indirect: "Komu bo poročal posredno?"
                },
                departments: {
                  direct: "S kom oz. s katerimi oddelki bo neposredno sodeloval?",
                  indirect: "S kom oz. s katerimi oddelki bo posredno sodeloval?"
                },
                budget: "Kako velik bo proračun, za katerega bo odgovoren?"
              },
              form: {
                label: "Oblika zaposlitve",
                selection: {
                  "fixed": "Določen čas",
                  "indefinitely": "Nedoločen čas",
                  "mandate": "Mandat",
                  "contract": "Pogodbeno delo"
                },
                note: "Oblika zaposlitve - opomba"
              },
              trial: "Poskusno obdobje",
              reason: {
                label: "Razlog za iskanje",
                selection: {
                  new: "Novo delovno mesto",
                  replacement: "Zamenjava (zaradi odhoda sodelavca, porodniške in drugo)",
                  expansion: "Širitev ekipe"
                }
              },
              salary: {
                label: "Okvirna višina bruto plače",
                fixed: "Okvirna fiksna bruto plača",
                variable: "Okvirna variabilna bruto plača"
              },
              bonuses: "Finančne nagrade / Bonusi / Udeležba pri dobičku",
              extras: {
                label: "Dodatne ugodnosti",
                selection: {
                  phone: "Mobilni telefon",
                  car: "Avto",
                  laptop: "Prenosni računalnik",
                  overtime: "Plačevanje nadur",
                  education: "Izobraževanja, usposabljanja",
                  pension: "Dodatno pokojninsko zavarovanje",
                  health: "Dodatno zdravstveno zavarovanje",
                  other: "Nefinančne nagrade"
                },
                note: "Dodatne ugodnosti - opomba"
              },
              city: "Kraj dela",
              worktime: {
                label: "Delovni čas",
                selection: {
                  fixed: "Fiksni delovni čas",
                  flexible: "Prilagodljiv delovni čas",
                  remote: "Možnost dela od doma"
                },
                note: "Delovni čas - opomba"
              },
              trips: {
                label1: "Delo vsebuje poslovna potovanja",
                label2: "Delo ne vsebuje poslovnih potovanj",
                note: "Poslovna potovanja - Koliko? Kam?"
              },
              startdate: "Želeni datum pričetka dela",
              career: "Karierne možnosti in razvoj",
              tools: "Orodja, ki jih v podjetju uporabljate (npr. ERP sistem)",
              takeover: {
                label: "Prevzem delovnega mesta",
                introduction: {
                  label: "Proces uvajanja",
                  toggle: {
                    label1: "Pri prevzemu delovnega mesta je uvajanje",
                    label2: "Pri prevzemu delovnega mesta ni uvajanja"
                  },
                  who: "Kdo bo izvajal uvajanje?",
                  time: "Koliko časa bo predvidoma trajalo uvajanje?",
                  how: "Na kakšen način bo potekalo uvajanje?"
                },
                training: {
                  label: "Treningi",
                  toggle: {
                    label1: "Pri prevzemu delovnega mesta so treningi",
                    label2: "Pri prevzemu delovnega mesta ni treningov"
                  },
                  location: "Lokacija?",
                  time: "Čas trajanja?"
                },
                mentoring: {
                  label: "Mentorstvo",
                  toggle: {
                    label1: "Pri prevzemu delovnega mesta je mentorstvo",
                    label2: "Pri prevzemu delovnega mesta ni mentorstva"
                  },
                  who: "Kdo je predvideni mentor?",
                  goal: "Cilji mentorstva?"
                }
              }
            },
            person: {
              headline: "ANALIZA ISKANE OSEBE",
              categories: {
                "professional-requirements": {
                  headline: "Strokovne zahteve delovnega mesta",
                  education: "Izobrazba",
                  "work-experience": "Delovne izkušnje in strokovna znanja",
                  "management-experience": "Vodstvene izkušnje",
                  "languages": "Znanje tujih jezikov"
                },
                "personal-requirements": {
                  headline: "Osebnostne zahteve delovnega mesta"
                },
                "ideal-candidate": {
                  headline: "Idealen kandidat",
                  notes: "Opombe"
                },
                "key-competences": {
                  headline: "KLJUČNE KOMPETENCE DELOVNEGA MESTA"
                }
              },
              level: "Stopnja zahteve",
              usage: "Nivo znanja",
              "professional-requirements": {
                education: {
                  education: {
                    degree: "Stopnja izobrazbe",
                    area: "Področje",
                    direction: "Smer"
                  }
                },
                "work-experience": {
                  experience: {
                    label: "Delovne izkušnje",
                    period: "Obdobje (let)",
                    workplace: "Delovno mesto",
                    description: "Opis izkušenj"
                  },
                  computer: {
                    label: "Računalniška znanja"
                  },
                  licenses: {
                    label: "Licence, certifikati"
                  },
                  other: {
                    label: "Drugo"
                  }
                },
                "management-experience": {
                  experience: {
                    period: "Obdobje (let)",
                    area: "Področje"
                  }
                },
                languages: {
                  lang: "Jezik",
                  en: "Angleški jezik",
                  de: "Nemški jezik",
                  hr: "Hrvaški/srbski jezik",
                  it: "Italijanski jezik",
                  other: "Drugo; vnesi jezik"
                }
              },
              "personal-requirements": {
                characteristics: "Osebnostne lastnosti"
              },
              "ideal-candidate": {
                education: "Izobrazba",
                experience: "Izkušnje",
                skills: "Veščine",
                competences: "Kompetence"
              },
              "key-competences": {
                instructions: "Pred vami je 13 značilnosti ljudi, splošnih kompetenc. Izmed vseh izberite 6 ključnih, ki so vam najpomembnejše za to delovno mesto.",
                choose: "Izberite"
              }
            },
            procedure: {
              headline: "SELEKCIJSKI POSTOPEK",
              person: {
                headline: "Odgovorna oseba za selekcijo pri naročniku",
                name: "Ime in priimek",
                phone: "Telefon",
                email: "E-mail"
              },
              flow: "Potek procesa selekcije pri naročniku (kdo bo opravljal razgovore, število predvidenih krogov, morebitne naloge…)"
            }
          },
          micro: {
            headline: "MIKRO OKOLJE",
            toggle: {
              label: "Mikro okolje",
              true: "V projektu izvajamo meritev mikro okolja.",
              false: "V projektu ne izvajamo meritev mikro okolja."
            }
          },
          competences: {
            sumo: {
              title: "Sposobnost upravljanja medosebnih odnosov",
              description: "Kako spreten je pri vzpostavljanju in vzdrževanju kakovostnih medosebnih odnosov. Kako prijetno se počutimo v njegovi družbi in kako učinkovit je pri upravljanju različnih osebnosti, različnih interesov in podobno."
            },
            ks: {
              title: "Komunikacijske sposobnosti",
              description: "Z lahkoto pretvarja misli v besede. Je zanimiv in prijeten sogovornik, dober pri pisanju ter uspešen pri predstavitvah."
            },
            std: {
              title: "Sposobnost timskega dela",
              description: "Sposobnost v timu zbrati različne posameznike in jih spodbuditi, da vsi skupaj dosežejo rezultate, ki so bistveno večji in drugačne kakovosti, kot če bi preprosto sešteli posamezne učinkovitosti. V timu delujejo povezovalno in integrativno."
            },
            uhk: {
              title: "Usmerjenost h kupcu",
              description: "Zaveda se pomena kupca in pomembnosti prilagojenega delovanja kupcu na vseh nivojih v podjetju. Zna raziskovati in delovati v smeri zadovoljevanja kupčevih sedanjih in prihodnjih potreb."
            },
            ucin: {
              title: "Učinkovitost",
              description: "Zna se hitro in učinkovito lotiti nalog. Dela se loti na način, da ga zanesljivo pripelje k cilju. Ne razmišlja preveč kako bi ga opravil, ampak sprejme odločitve, ki namen hitro pretvorijo v vedenje."
            },
            del: {
              title: "Delavnost",
              description: "Sposoben je iskati delo, ga najti, opraviti, če je njegovo, ga delegirati, če oceni, da je bolje, da ga opravi nekdo drug in na koncu dati povratno informacijo o doseženih ciljih. Z zgledom pokaže, kakšen je pravi odnos do dela, hkrati pa je z zgledom sposoben pripraviti sodelavce in podrejene, da mu pri delu sledijo."
            },
            vztr: {
              title: "Vztrajnost",
              description: "Delovnih nalog se loteva z veliko energije in je pri delu sposoben vztrajati, dokler cilja ne osvoji. Ne popusti pri prvi oviri in ne preneha z aktivnostmi, če se pri delu pokaže težava. Zna presoditi, kdaj je smiselno v delo vložiti še več napora, energije, da bo delo v vsakem primeru dokončano."
            },
            zane: {
              title: "Zanesljivost",
              description: "Pri prevzemanju nalog in odgovornosti je resen, dosleden in odgovoren. Za naloge in cilje, ki jih prevzame, smo lahko brez skrbi, saj bodo gotovo opravljeni in doseženi."
            },
            nat: {
              title: "Natančnost",
              description: "Naloge izvaja brez napak, jih marljivo pregleduje in po potrebi popravlja. Skrbno se osredotoča na podrobnosti naloge in poskrbi, da je delo opravljeno kvalitetno."
            },
            os: {
              title: "Organizacijske sposobnosti",
              description: "Zna organizirati vire in procese, da dosežejo maksimalno učinkovitost. Kompleksne naloge je sposoben razdeliti na manjše, pregledne in obvladljive korake."
            },
            scv: {
              title: "Sposobnost ciljnega vodenja",
              description: "Zna postaviti jasne cilje za posameznike, dodeliti odgovornosti, meriti izvedbo, dajati povratno informacijo za dosego pričakovanih rezultatov in oceniti uspešnosti dela."
            },
            ssv: {
              title: "Sposobnost strateškega vodenja",
              description: "Zna določiti niz aktivnosti oz. strategijo, ki pelje k uresničevanju vizije. Sposoben je predvideti trende, ki so širši od področja poslovanja organizacije v kateri je zaposlen. Izbira rešitve, ki delujejo dolgoročno."
            },
            us: {
              title: "Odpornost na stres",
              description: "Kako visok prag ima pri tem, da zaznava določen dražljaj ali informacijo kot potencialno motečo ali ogrožajočo. Zna tudi v zahtevnih osebnih in poslovnih situacijah zmore ohraniti mirno kri. Hitro se regenerira po stresnih in konfliktnih dogodkih."
            }
          }
        }
      },
      contract: {
        preparing_document: "Pripravljamo vaš dokument",
        contract_preview_unavailable: {
          text1: "Predogled pogodbe ni na voljo.",
          text2: "Za več informacij se obrnite na Competo."
        },
        work_field: "Delovno mesto",
        candidates_count: "Število kandidatov",
        service: "Storitev",
        installment: "Obroki",
        warranty_period: "Garancijsko obdobje (št. mesecev)",
        payment_type: "Način obračunavanja honorarja",
        payment_deadline: "Rok plačila (št. dni)",
        salary: "Honorar",
        salary_currency: "EUR",
        invoice_number: "Št. naročilnice na fakturi",
        invoice_note: "Dodatna opomba na fakturi",
        execution_deadline: "Rok izvedbe (št. dni)",
        fee: {
          fix: "Fiksni znesek v EUR",
          percent: "% bruto plače"
        },
        fee_lump: "Pavšalni znesek ob podpisu",
        fee_interim: "Vmesni honorar",
        fee_final: "Končni honorar",
        no_contract: {
          text1: "Pogodba ni na voljo.",
          text2: "Za več informacij se obrnite na Competo."
        }
      },
      ad: {
        hidden: {
          text1: "Vsebina trenutno ni na voljo.",
          text2: "Za več informacij se obrnite na Competo."
        },
        locked: {
          text1: "Vsebina je trenutno zaklenjena.",
          text2: "Lahko jo vidite, vendar je ne morete urejati."
        },
        confirm_ad: "Potrdi oglas",
        buttons: {
          edit: "Uredi",
          publish: "Objavi",
          create: "Ustvari oglas"
        },
        notify: {
          confirm: {
            success: "Oglas je bil potrjen.",
            error: "Pri potrjevanju oglasa je prišlo do napake."
          }
        },
        send_email_popup: {
          label: "Pošlji sporočilo",
          title: "Pošlji sporočilo Competu",
          message: "V spodnje polje vpišite sporočilo. To bo preko elektronskega naslova poslano odgovorni osebi na tem projektu.",
          cancel: "Prekliči",
          confirm: "Pošlji",
          notify: {
            success: "Sporočilo je bilo poslano.",
            error: "Pri pošiljanju sporočila je prišlo do napake."
          }
        },
        doesnt_exist: "Oglas ne obstaja",
        error_message: "Prišlo je do napake. Preverite, če ste izpolnili vse podatke.",
        ad_drawer: {
          title: "Nov oglas",
          headline: "Oglas",
          doesnt_exist: {
            text1: "Oglas še ni pripravljen.",
            text2: "Za več informacij se obrnite na Competo."
          },
          fields: {
            name: {
              label: "Naziv delovnega mesta",
              placeholder: "Naziv delovnega mesta"
            },
            work_field: {
              label: "Področja dela (izberete lahko do 3 področja)"
            },
            work_time: {
              label: "Delovni čas"
            },
            work_experience: {
              label: "Delovne izkušnje"
            },
            salary_range: {
              label: "Mesečno plačilo"
            },
            region: {
              label: "Regija, v kateri se delo opravlja"
            },
            employment_type: {
              label: "Oblika zaposlitve"
            },
            education_level: {
              label: "Zahtevana stopnja izobrazbe"
            },
            career_level: {
              label: "Karierni nivo"
            },
            date: {
              label: "Oglas naj bo objavljen",
              placeholder: "Izberite kdaj naj bo oglas objavljen"
            },
            job_category: {
              label: "Kategorija zaposlitve"
            },
            type: {
              label: "Tip oglasa"
            },
            is_foreign: {
              label: "Tujina",
              description1: "Delo se nahaja v tujini",
              description2: "Delo se nahaja v Sloveniji"
            },
            has_trial_work: {
              label: "Poskusna delovna doba",
              description1: "Delo ima poskusno delovno dobo",
              description2: "Delo nima poskusne delovne dobe"
            },
            trial_work_months: {
              label: "Poskusna delovna doba (št. mesecev)"
            },
            city: {
              label: "Kraj",
              placeholder: "Kraj dela"
            },
            contact_name: {
              label: "Kontaktna oseba",
              placeholder: "Kontaktna oseba"
            },
            contact_phone: {
              label: "Telefon",
              placeholder: "Telefon"
            },
            apply_email: {
              label: "Elektronski naslov za prijavo",
              placeholder: "Vnesite elektronski naslov za prijavo"
            },
            apply_url: {
              label: "Spletna stran za prijavo",
              placeholder: "Vnesite spletno stran za prijavo"
            },
            offerings: {
              label: "Nudimo",
              placeholder: "Vpišite kaj podjetje ponuja"
            },
            expectations: {
              label: "Pričakujemo",
              placeholder: "Vpišite kaj podjetje pričakuje"
            },
            about_company: {
              label: "O podjetju",
              placeholder: "Na kratko predstavite podjetje"
            },
            short_description: {
              label: "Povzetek oglasa",
              placeholder: "Vpišite kratko besedilo oglasa"
            },
            description: {
              label: "Opis delovnega mesta",
              placeholder: "Vpišite glavno besedilo oglasa"
            },
            types: {
              type_1: "Free",
              type_2: "Enostavni",
              type_3: "Osnovni",
              type_4: "Premium",
              type_5: "Executive",
              type_6: "Headhounter",
              type_7: "Študentski oglas"
            },
            headlines: {
              work_place: "O delovnem mestu",
              candidate: "Lastnosti kandidata",
              offerings: "Kaj podjetje ponuja?",
              expectations: "Zaželena znanja, izkušnje in kompetence",
              company: "Predstavitev podjetja",
              description: "Izzivi, s katerimi se bo srečeval izbrani kandidat",
              contact: "Kontaktni podatki"
            }
          },
          buttons: {
            cancel: "Prekliči",
            confirm: "Shrani",
            publish: "Objavi",
            lock: "Potrdi oglas",
            transfer: "Prenesi"
          },
          tooltips: {
            transfer: "Prenesi podatke iz analize. Po prenosu je potrebno shraniti oglas."
          },
          url: "Povezava do oglasa: ",
          notify: {
            save: {
              missing_name: "Za shranjevanje oglasa je potrebno vnesti Naziv delovnega mesta.",
              success: "Oglas je bil shranjen.",
              error: "Pri shranjevanju oglasa je prišlo do napake."
            }
          }
        }
      },
      candidates_tab: {
        candidates_list: {
          proposed: {
            headline: "Potrjevanje kandidatov",
            name: "Ime in priimek",
            confirm: "Potrdi kandidata",
            empty_list: {
              text1: "Seznam kandidatov trenutno še ni na voljo.",
              text2: "Za več informacij se obrnite na Competo."
            },
            confirm_btn: "Potrdi kandidata",
            decline_btn: "Zavrni kandidata",
            report: "Poročilo psihologa",
            report_consultant: "Poročilo svetovalca",
            cv: "CV",
            education: "Izobrazba",
            experience: "Izkušnje",
            competences: "Kompetence",
            langs: "Jeziki",
            adequacy: {
              "1": "Ne dosega",
              "2": "Dosega",
              "3": "Presega"
            },
            status_client: {
              "not-sent": "Ni poslan",
              sent: "Poslan",
              confirmed: "Potrjen",
              selected: "Izbran",
              rejected: "Zavrnjen"
            },
            show_competence_group: "Prikaži kompetence v skupini ",
            hide_competence_group: "Skrij kompetence v skupini ",
            show: "Prikaži ",
            hide: "Skrij ",
            testing_properties: "Poročila",
            decline_popup: {
              title: "Zavrnitev kandidata",
              message: "V spodnje polje vpišite razlog za zavrnitev kandidata.",
              cancel: "Prekliči",
              confirm: "Nadaljuj"
            },
            notify: {
              confirm: {
                success: "Kandidat je bil potrjen.",
                error: "Pri potrjevanju kandidata je prišlo do napake."
              },
              decline: {
                success: "Kandidat je bil zavrnjen.",
                error: "Pri zavrnitvi kandidata je prišlo do napake."
              }
            },
            groups: {
              education: "Izobrazba",
              experience: "Zahteve delovnega mesta",
              language: "Znanje tujih jezikov"
            }
          },
          selection: {
            headline: "Nabor kandidatov, ki jih želimo spoznati",
            name: "Ime in priimek",
            confirm: "Izberi kandidata",
            empty_list: {
              text1: "Seznam kandidatov trenutno še ni na voljo.",
              text2: "Za več informacij se obrnite na Competo."
            },
            confirm_btn: "Izberi kandidata",
            decline_btn: "Zavrni kandidata",
            report: "Poročilo psihologa",
            report_consultant: "Poročilo svetovalca",
            cv: "CV",
            education: "Izobrazba",
            experience: "Izkušnje",
            competences: "Kompetence",
            langs: "Jeziki",
            adequacy: {
              "1": "Ne dosega",
              "2": "Dosega",
              "3": "Presega"
            },
            status_client: {
              "not-sent": "Ni poslan",
              sent: "Poslan",
              confirmed: "Potrjen",
              selected: "Izbran",
              rejected: "Zavrnjen"
            },
            show_competence_group: "Prikaži kompetence v skupini ",
            hide_competence_group: "Skrij kompetence v skupini ",
            show: "Prikaži ",
            hide: "Skrij ",
            testing_properties: "Poročila",
            decline_popup: {
              title: "Zavrnitev kandidata",
              message: "V spodnje polje vpišite razlog za zavrnitev kandidata.",
              cancel: "Prekliči",
              confirm: "Nadaljuj"
            },
            notify: {
              select: {
                success: "Kandidat je bil izbran.",
                error: "Pri izbiri kandidata je prišlo do napake."
              },
              decline: {
                success: "Kandidat je bil zavrnjen.",
                error: "Pri zavrnitvi kandidata je prišlo do napake."
              }
            },
            groups: {
              education: "Izobrazba",
              experience: "Zahteve delovnega mesta",
              language: "Znanje tujih jezikov"
            }
          }
        },
        candidates_expired: {
          text1: "Čas za ogled kandidatov je potekel.",
          text2: "Za več informacij se obrnite na Competo."
        }
      },
      micro_tab: {
        candidates_list: {
          headline: "Kandidati v mikro okolju",
          name: "Ime in priimek",
          add_users: "Upravljanje zaposlenih",
          report: "Poročilo psihologa",
          education: "Izobrazba",
          experience: "Izkusnje",
          competences: "Kompetence",
          langs: "Jeziki",
          adequacy: {
            "1": "Ne dosega",
            "2": "Dosega",
            "3": "Presega"
          },
          empty_list: {
            text1: "Lista kandidatov je prazna.",
            text2: "Zaposlenega v vašem podjetju lahko dodate tako, da odprete listo za upravljanje kandidatov."
          }
        }
      },
      candidates_drawer: {
        title: "Kandidat",
        tabs: {
          overview: "Pregled",
          competences: "Kompetence",
          opinion: "Mnenje svetovalca",
          summary: "Povzetek Ψ",
          other: "Ostalo"
        },
        overview: {
          basic: {
            headline: {
              candidate: "Podatki o kandidatu",
              employee: "Podatki o zaposlenemu"
            },
            gender: {
              title: "Spol",
              male: "M",
              female: "Ž"
            },
            birthday: "Datum rojstva",
            education: "Izobrazba",
            phone: "Telefonska številka",
            email: "Elektronski naslov",
            redirect: {
              candidate: "Prikaži kandidata",
              employee: "Prikaži zaposlenega"
            },
            position: "Naziv oddelka in pozicije",
            address: "Naslov",
            company: "Podjetje",
            career: "Karierni nivo"
          },
          files: {
            report: {
              tooltip_download: "Prenesi poročilo",
              doesnt_exist: "Poročilo ne obstaja"
            }
          },
          competences: {
            headline: "Kompetence projekta",
            content: {
              value_percentile: {
                text: "Percentil",
                title: "P"
              },
              value_denom: {
                text: "Ocena",
                title: "O"
              },
              value: {
                text: "Število surovih točk",
                title: "T"
              }
            },
            no_data: "Graf ni na voljo, ker testiranje ne vsebuje kompetenc."
          },
          intelligence: {
            headline: "Splošna čustvena inteligentnost",
            rate: {
              "0": "podatek ni na voljo",
              "1": "zelo nizko",
              "2": "nizko",
              "3": "povprečno",
              "4": "visoko",
              "5": "zelo visoko"
            }
          }
        },
        competences: {
          headline: "Vse kompetence",
          content: {
            value_percentile: {
              text: "Percentil",
              title: "P"
            },
            value_denom: {
              text: "Ocena",
              title: "O"
            },
            value: {
              text: "Število surovih točk",
              title: "T"
            }
          }
        },
        opinion: {
          opinion_headline: "Mnenje o kandidatu na tem projektu",
          no_opinion: "Na tem projektu še ni bilo dodanega mnenja o kandidatu.",
          other_opinion_headline: "Mnenja o kandidatu na drugih projektih",
          no_other_opinion: "Drugi projekti ne vsebujejo mnenja o tem kandidatu."
        },
        summary: {
          summary_headline: "Povzetek o kandidatu na tem projektu",
          no_summary: "Na tem projektu še ni bilo dodanega povzetka o kandidatu.",
          other_summary_headline: "Povzetki o kandidatu na drugih projektih",
          no_other_summary: "Drugi projekti ne vsebujejo povzetka o tem kandidatu."
        }
      },
      add_candidates_drawer: {
        title: "Upravljanje liste kandidatov",
        table: {
          search: "Iskanje ...",
          name: "Ime in priimek",
          buttons: {
            add: "Dodaj novega zaposlenega"
          }
        },
        buttons: {
          save: "Dodaj",
          cancel: "Prekliči"
        }
      },
      add_candidates_popup: {
        headline: "Osnovni osebni podatki",
        fields: {
          title: {
            label: "Ime in priimek",
            placeholder: "Vpišite ime in priimek zaposlenega"
          },
          email: {
            headline: "Elektronski naslov",
            headline_description: "Vpis elektronskega naslova omogoča pošiljanje testov in drugih obveščanj.",
            label: "Elektronski naslov",
            placeholder: "Vpišite elektronski naslov"
          }
        },
        buttons: {
          save: "Shrani zaposlenega",
          cancel: "Prekliči"
        },
        success_message: "Uspešno ste shranili zaposlenega."
      }
    }
  }
}
