<template>
    <UikTopBar
        class="navigation"
        :class="{
            'navigation--side': sideNav,
            'navigation--collapsed': menuCollapsed,
            'navigation--open': openNav
        }">
        <div class="uik-top-bar__container">
            <button
                class="uik-top-bar__open-navigation-btn"
                :class="{'uik-top-bar__open-navigation-btn--close': openNav}"
                @click="openNav = !openNav"
            >
                <div v-for="n in 3" :key="n"/>
            </button>

            <UikTopBarSection class="top-bar__logo">
                <router-link to="/dashboard">
                    <img src="/img/COMPETO_LOGO_2024.svg" alt="Competo Logo"/>
                    <span>PARTNER</span>
                </router-link>
                <button
                    class="menu__toggleNav"
                    @click="toggleMenuCollapse"
                >
                    <div v-for="n in 3" :key="n"/>
                </button>
                <button
                    class="menu__openNav"
                    @click="openNav = !openNav"
                >
                    <div v-for="n in 3" :key="n"/>
                </button>
            </UikTopBarSection>

            <UikTopBarSection class="uik-top-bar__navigation__container">
                <div class="top-bar__navigation-scrollable">
                    <UikTopBarLinkContainer
                        v-if="$route.name !== 'User Info'"
                        class="uik-top-bar__navigation"
                        :class="{'uik-top-bar__navigation--open': openNav}"
                    >
                        <UikTopBarLink :title="'Odprta opravila'" router-link="/dashboard" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'tasks']"/>
                            <span>{{ $t('Global.navigation.dashboard') }}</span>
                        </UikTopBarLink>
                        <UikTopBarLink :title="'Iskanje in selekcija'" router-link="/projects" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'rocket']"/>
                            <span>{{ $t('Global.navigation.projects') }}</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('PortalCompany/view')" :title="'HR analitika'" router-link="/analytics" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'chart-simple']" style="transform: translateX(2px);"/>
                            <span>{{ $t('Global.navigation.hranalytics') }}</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('PortalTesting/view')" :title="'Testiranja'" router-link="/testings" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'vial']"/>
                            <span>{{ $t('Global.navigation.testings') }}</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('PortalMeasure/view')" :title="'Meritve'" router-link="/measures" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'tachometer']"/>
                            <span>{{ $t('Global.navigation.measures') }}</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('PortalFocusGroup/view')" :title="'Fokusne skupine'" router-link="/focus-groups" @click.native="openNav = false">
                            <UikIcon :icon="['far', 'clipboard-user']" />
                            <span>{{ $t('Global.navigation.focusgroups') }}</span>
                        </UikTopBarLink>
                        <UikTopBarLink :title="'Pogodbe'" router-link="/contracts" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'file-contract']" />
                            <span>{{ $t('Global.navigation.contracts') }}</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('PortalCompanyEmployee/view')" :title="'Zaposleni'" router-link="/employees" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'users']" />
                            <span>{{ $t('Global.navigation.employees') }}</span>
                        </UikTopBarLink>
                        <UikTopBarLink :title="'Vprašalniki'" router-link="/questionnaires" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'clipboard-list']" />
                            <span>{{ $t('Global.navigation.questionnaires') }}</span>
                        </UikTopBarLink>
                    </UikTopBarLinkContainer>
                </div>

                <UikDivider vertical margin/>

                <div class="uik-top-bar__avatar-container">
                    <UikAvatar
                        class="uik-avatar--top-bar"
                        :name="user.name"
                        :textBottom="user.position_title"
                        :imageUrl="user.avatar_url ? user.avatar_url : ''"
                        color="primary"
                        @click.native="dropdownSettings = true"
                    />
                    <Uikon class="navigation__settings-icon" icon="settings"/>
                    <UikDropdown v-model="dropdownSettings">
                        <UikDropdownItem v-if="$route.name !== 'User Info'" @click.native="$router.push('/settings'); dropdownSettings=false; openNav = false;">
                            <UikIcon :icon="['far', 'cog']" style="margin-right: 8px;"/>
                            {{ $t('Global.navigation.settings') }}
                        </UikDropdownItem>
                        <UikDropdownItem @click.native="dropdownSettings=false; openNav = false; $api.Auth.logout()">
                            <UikIcon :icon="['fal', 'sign-out-alt']" style="margin-right: 8px;"/>
                            {{ $t('Global.navigation.logout') }}
                        </UikDropdownItem>
                    </UikDropdown>
                </div>
            </UikTopBarSection>
        </div>
        <UikOverlay
            class="navigation__openNav-overlay"
            :visible="openNav"
            @close="openNav = false"
        />
    </UikTopBar>
</template>

<script>
export default {
  data () {
    return {
      openNav: false,
      menuCollapsed: false,
      dropdownSettings: false
    }
  },
  computed: {
    user () {
      return this.$api.Auth && this.$api.Auth.state && this.$api.Auth.state.user
        ? this.$api.Auth.state.user
        : {}
    },
    userPermissions () {
      if (!this.user.permissions) return []
      return this.user.permissions
    },
    sideNav () {
      return true
    }
  },
  methods: {
    iconStyle (path, exact) {
      let style = "far"
      if (
        (exact && this.$route.path === path) ||
        (!exact && this.$route.path.startsWith(path))
      ) {
        style = "fas"
      }
      return style
    },
    hasPermission (index) {
      return true
      // @todo Implement

      // if (Object.keys(this.userPermissions).length > 0) {
      //   return this.userPermissions.indexOf("view/" + index) >= 0
      // }
      // return false
    },
    toggleMenuCollapse () {
      this.menuCollapsed = !this.menuCollapsed
      localStorage.menuCollapsed = this.menuCollapsed
    }
  }
}
</script>

<style lang="scss">
.navigation {
    .uik-top-bar__container {
        .uik-top-bar__navigation__container {
            .top-bar__navigation-scrollable {
                overflow-y: auto;
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
