export default {
  views: {
    list: {
      headline: {
        open: "Open projects",
        closed: "Completed projects"
      },
      empty_list_notice: {
        none: "No open projects",
        closed: "All projects are closed"
      },
      card: {
        button_text: {
          else: "Open project"
        },
        created_at: "Created",
        updated_at: "Last change",
        finished_at: "Closed",
        hiring_manager: "Hiring manager",
        action_needed: "Action needed",
        company: "Company"
      },
      header: {
        title: "Projects",
        search_placeholder: "Search ..."
      },
      table: {
        title: "Title",
        created_at: "Created",
        updated_at: "Updated",
        options: "Options",
        status: "Status"
      }
    },
    edit: {
      back_button: "To the list of projects",
      tabs: {
        contract: "Contract",
        adm: "Job position analysis",
        ad: "Ad",
        candidates: "Candidates",
        micro: "Work micro-environment",
        overview: "Overview"
      },
      basic: {
        company: "Company",
        hiring_manager: "Hiring manager",
        project_status: "Status",
        finished: "End date"
      },
      overview: {
        funnel: {
          headline: "Funnel",
          list_description: {
            applied: "Identified candidates",
            interesting: "Reviwed candidates with potential.",
            invited: "Candidates invited for an interview.",
            testing: "Tested candidates.",
            proposed: "Shortlisted candidates.",
            confirmed: "Confirmed candidates.",
            selected: "Chosen candidate"
          }
        },
        status_widget: {
          header: {
            project_status: "Project status",
            finished: "Competo",
            approved: "Client approval"
          },
          statuses: {
            adm: "JPA",
            ad: "Ad",
            candidates_long_list: "Shortlist sent",
            notify: "Candidates shortlisted",
            selected_candidates: "Candidate selected",
            confirmed_candidates: "Candidates confirmed"
          }
        },
        time_to_delivery: "Estimated due date to present shortlist:",
        time_to_delivery_unavailable: "Estimate not available",
        hiring_managers: {
          title: "Hiring managers",
          empty_list: "Lista is empty"
        }
      },
      adm: {
        competence_title: "projects",
        hidden: {
          text1: "Content not available.",
          text2: "Contact Competo for more info."
        },
        locked: {
          text1: "Contect is locked.",
          text2: "Content can be reviewed, but not edited."
        },
        confirm_adm: "Confirm Job position analysis",
        notify: {
          confirm: {
            success: "Job position analysis is confirmed.",
            error: "Error confirming Job position analysis."
          }
        },
        send_email_popup: {
          label: "Send message",
          title: "Send message to Competo",
          message: "Please enter message in the form bellow. Message will be sent by email to Project manager.",
          cancel: "Cancel",
          confirm: "Send",
          notify: {
            success: "Message was sent.",
            error: "Error sending message."
          }
        },
        competences_widget: {
          title: "General competences",
          edit: "Edit",
          use_competences: "Use entry data from ADM"
        },
        report_widget: {
          title: "Competences",
          doesnt_exist: {
            text1: "List of competences not available",
            text2: "Contact Competo for more info."
          },
          buttons: {
            confirm: "Confirm JPA",
            create_template: "Create template",
            save_template: "Save template",
            save: "Save",
            new_competence: "New competence",
            new_competence_input: {
              placeholder: "Enter new competence"
            }
          }
        },
        competences_drawer: {
          title: "Competence editor",
          search: "Search ...",
          tag: "Number of competences: ",
          table: {
            title: "Title"
          },
          cancel_button: "Cancel",
          save_button: "Save"
        },
        analysis_widget: {
          title: "Analysis",
          description: "Description",
          experience: "Experience and skills",
          buttons: {
            save: "Save",
            new_competence_input: {
              placeholder: "Enter new competence"
            }
          },
          notify: {
            form: {
              success: "Form saved.",
              error: "Error saving form."
            },
            micro: {
              success: "Micro environment updated.",
              error: "Error updating Micro environment."
            },
            removed_competence: {
              "1": "Competence ",
              "2": " was removed. You can select max 6 competences."
            }
          },
          doesnt_exist: {
            text1: "Analysis not ready.",
            text2: "Contact Competo for more info."
          },
          headlines: {
            education: "Education",
            experience: "Job requirements",
            language: "Languages"
          },
          fields: {
            headlines: {
              job: "Position",
              employment: "Employment"
            },
            company: {
              headline: "COMPANY ANALYSIS (WORK ENVIRONMENT)",
              vision: "Vision and strategy of the company or department",
              values: "Company values",
              milestones: "Key milestones in company history that influence your culture",
              activities: "Employee wellbeing (fresh fruits, sport activities, gatherings, teambuildings…)",
              advantages: "Key differentiators that separate your company from competitors",
              adjectives: "Three adjectives you would use to describe your company",
              advice: "Your first advice to a future colleague"
            },
            job: {
              headline: "JOB POSITION ANALYSIS",
              title: "Job title",
              purpose: "Job Purpose",
              responsibilities: "Responsibilities (3 most important), including KPIs",
              tasks: {
                label: "Main work areas (at least 5 to most 8 important)",
                placeholder: "Please try to divide into subareas, for eg. management of sales, distributers, quality, employees, legislation, reporting&analysis"
              },
              micro: {
                label: "Micro organization ",
                persons: {
                  direct: "How many employees will he/she manage directly?",
                  indirect: "How many employees will he/she manage indirectly?"
                },
                report: {
                  direct: "Direct reporting line?",
                  indirect: "Dotted reporting line?"
                },
                departments: {
                  direct: "With whom/which departments will he/she be collaborating directly? ",
                  indirect: "With whom/which departments will he/she be collaborating indirectly?"
                },
                budget: "Budget he/she will be accountable for?"
              },
              form: {
                label: "Type of Employment",
                selection: {
                  "fixed": "Fixed/defined period",
                  "indefinitely": "Indefinite period ",
                  "mandate": "Mandate",
                  "contract": "Contract work"
                },
                note: "Type of Employment - remarks"
              },
              trial: "Trial period ",
              reason: {
                label: "Reason for new hire",
                selection: {
                  new: "New position",
                  replacement: "Replacement (e.g. due to replacement, maternity leave or other)",
                  expansion: "Team expansion"
                }
              },
              salary: {
                label: "Estimated gross salary ",
                fixed: "Estimated fixed gross salary",
                variable: "Variable part of the gross salary"
              },
              bonuses: "Financial rewards/Bonus system/Profit sharing",
              extras: {
                label: "Other benefits",
                selection: {
                  phone: "Cell-phone",
                  car: "Car",
                  laptop: "Laptop",
                  overtime: "Overtime payment",
                  education: "Trainings, seminars",
                  pension: "Extra pension insurance",
                  health: "Extra health insurance",
                  other: "Nonfinancial bonuses"
                },
                note: "Other benefits - remarks"
              },
              city: "Work location",
              worktime: {
                label: "Working time",
                selection: {
                  fixed: "Fixed",
                  flexible: "Flexible",
                  remote: "Option to work from home"
                },
                note: "Working time - remarks"
              },
              trips: {
                label1: "Job requires business trips",
                label2: "Job does not require business trips",
                note: "Business trips - How many? Where?"
              },
              startdate: "Desired start date of work",
              career: "Career development and opportunities",
              tools: "Key (IT) tools used by your company (e.g. ERP system)",
              takeover: {
                label: "Introduction to job",
                introduction: {
                  label: "Onboarding",
                  toggle: {
                    label1: "Onboarding system in place",
                    label2: "No onboarding system in place"
                  },
                  who: "Person responsible for onboarding?",
                  time: "Estimated time needed for onboarding?",
                  how: "Onboarding structure?"
                },
                training: {
                  label: "Trainings",
                  toggle: {
                    label1: "Training system in place",
                    label2: "No training system in place"
                  },
                  location: "Location?",
                  time: "Required time?"
                },
                mentoring: {
                  label: "Mentorship",
                  toggle: {
                    label1: "Mentorship system in place",
                    label2: "No Mentorship system in place"
                  },
                  who: "Mentor name and position?",
                  goal: "Mentorship goals?"
                }
              }
            },
            person: {
              headline: "JOB REQUIREMENTS",
              categories: {
                "professional-requirements": {
                  headline: "Professional job requirements",
                  education: "Education",
                  "work-experience": "Work experience and functional skills",
                  "management-experience": "Leadership/managerial skills",
                  "languages": "Languages"
                },
                "personal-requirements": {
                  headline: "Personal profile requirements"
                },
                "ideal-candidate": {
                  headline: "Ideal candidate",
                  notes: "Opombe"
                },
                "key-competences": {
                  headline: "KEY GENERAL COMPETENCES FOR THE JOB"
                }
              },
              level: "Requirement level",
              usage: "Skill level",
              "professional-requirements": {
                education: {
                  education: {
                    degree: "Education level",
                    area: "Field",
                    direction: "Specialisation"
                  }
                },
                "work-experience": {
                  experience: {
                    label: "Working experience",
                    period: "Duration (years)",
                    workplace: "Title",
                    description: "Description (skills and experience)"
                  },
                  computer: {
                    label: "IT skills"
                  },
                  licenses: {
                    label: "Licences, certificates"
                  },
                  other: {
                    label: "Other"
                  }
                },
                "management-experience": {
                  experience: {
                    period: "Duration (years)",
                    area: "Field"
                  }
                },
                languages: {
                  lang: "Language",
                  en: "English",
                  de: "German",
                  hr: "Croatian/Serbian",
                  it: "Italian",
                  other: "Other; enter language"
                }
              },
              "personal-requirements": {
                characteristics: "Personality traits"
              },
              "ideal-candidate": {
                education: "Education",
                experience: "Experience",
                skills: "Skills",
                competences: "Competences"
              },
              "key-competences": {
                instructions: "Below you can find a list of 13 general competences. Please select 6 key competences that are most important to you regarding the job position.",
                choose: "Check selected"
              }
            },
            procedure: {
              headline: "SELECTION PROCESS",
              person: {
                headline: "Client’s project contact person ",
                name: "First and last name",
                phone: "Phone",
                email: "E-mail"
              },
              flow: "Client selection process (who will perform interviews, how many interviews are expected, required assignments)"
            }
          },
          micro: {
            headline: "MICRO-ENVIRONMENT EVALUATION",
            toggle: {
              label: "Micro environment",
              true: "Micro environment evaluation requested.",
              false: "Micro environment evaluation NOT requested."
            }
          },
          competences: {
            sumo: {
              title: "Interpersonal management skills",
              description: "Reflect how skilled is someone in establishing and maintaining quality interpersonal relations. How likely is someone to feel comfortable in his/her company and how effective is he/her in managing different personalities and interests."
            },
            ks: {
              title: "Communication skills",
              description: "Easily converts thoughts into words. He/she is an interesting and pleasant interlocutor, good at writing and successful in presentations."
            },
            std: {
              title: "Teamwork skills",
              description: "The ability to build teams of individuals with different personality traits and lead them to achieve results that are significantly better in comparison to individual performances. He or she connects and integrates the team."
            },
            uhk: {
              title: "Customer orientation",
              description: "He/she is aware of customer importance and importance of personalized customer approach at all levels within a company. He/she can explore and work towards meeting the customer's current and future needs."
            },
            ucin: {
              title: "Effectiveness",
              description: "Can be quick and effective in addressing tasks. Undertakes work in a way that leads to the goal. He or she doesn’t overthink decisions. On the contrary, he or she quickly converts intention to behaviour that leads to reaching set targets."
            },
            del: {
              title: "Diligence",
              description: "Is able to look for find work, get it done, if needed to delegate it and give feedback on achievements at conclusion. He/she leads by example with the right work attitude and at the same time is capable of encouraging peers and co-workers to follow."
            },
            vztr: {
              title: "Persistence",
              description: "He/she Is energetic at addressing individual tasks and is able to persevere until the goals are reached. He/she does not give up at the first obstacle and does not stop, if a problem arises. Knows how to determine when it makes sense to put more effort and energy into work in order to reliably complete a task."
            },
            zane: {
              title: "Reliability",
              description: "In assuming duties and responsibilities he/she is consistent and accountable. For tasks and objectives that he/she takes over we can be sure they will be carried out and targets met."
            },
            nat: {
              title: "Precision",
              description: "Tasks are performed without error, they are diligently examined and, if necessary, mended. Carefully focuses on the details of the task and ensures that the work is done according to quality standards."
            },
            os: {
              title: "Organizational skills",
              description: "Can organize resources and processes in order to achieve maximum efficiency. Is able to break down tasks into smaller and more manageable subtasks."
            },
            scv: {
              title: "Operational leadership skills",
              description: "He/she can set clear objectives for individuals, assign responsibility, measure performance, give feedback to achieve expected results and assess the work performance."
            },
            ssv: {
              title: "Strategic leadership skills",
              description: "He/she can establish set of activities, strategies that lead to achieving goals set in company vision. He/she can foresee trends that are wider than company current operation. He/she seeks for solutions that work on the long run."
            },
            us: {
              title: "Stress management",
              description: "How high is one’s threshold in perceiving certain stimuli or information as a potentially disruptive or threatening. He/she is able to keep calm in demanding personal and business situations. Can also recover fast after the stress and conflicting events."
            }
          }
        }
      },
      contract: {
        preparing_document: "Your document is being prepared",
        contract_preview_unavailable: {
          text1: "Contract preview not available.",
          text2: "Contact Competo for more info."
        },
        work_field: "Job Title",
        candidates_count: "Number of candidates",
        service: "Services",
        installment: "Instalments",
        warranty_period: "Warranty period (no. months)",
        payment_type: "Payment structure",
        payment_deadline: "Payment due (no. days)",
        salary: "Salary",
        salary_currency: "EUR",
        invoice_number: "Purchase no. if required on invoice",
        invoice_note: "Additional remarks to be added on invoice",
        execution_deadline: "Delivery date (no. days)",
        fee: {
          fix: "Fixed fee in EUR",
          percent: "% of gross salary"
        },
        fee_lump: "Flat-rate due after signing",
        fee_interim: "Intermediate fee due after presenting candidates",
        fee_final: "Final fee",
        no_contract: {
          text1: "Contract is not available.",
          text2: "Contact Competo for more info."
        }
      },
      ad: {
        hidden: {
          text1: "Content not available.",
          text2: "Contact Competo for more info."
        },
        locked: {
          text1: "Content locked.",
          text2: "Content can be viewed, but not edited."
        },
        confirm_ad: "Confirm ad text",
        buttons: {
          edit: "Edit",
          publish: "Publish",
          create: "Create Ad"
        },
        notify: {
          confirm: {
            success: "Ad is confirmed.",
            error: "Error confirming ad."
          }
        },
        send_email_popup: {
          label: "Send message",
          title: "Send message to Competo",
          message: "Enter text in form bellow. Message will be sent by email to project manager.",
          cancel: "Cancel",
          confirm: "Send",
          notify: {
            success: "Message sent.",
            error: "Error sending message."
          }
        },
        doesnt_exist: "Ad not available",
        error_message: "Error. Please re-check all entries.",
        ad_drawer: {
          title: "New ad",
          headline: "Ad",
          doesnt_exist: {
            text1: "Ad is not ready.",
            text2: "Contact Competo for more info."
          },
          fields: {
            name: {
              label: "Job Title",
              placeholder: "Job title"
            },
            work_field: {
              label: "Field of work (please pick up to 3 fields)"
            },
            work_time: {
              label: "Working time"
            },
            work_experience: {
              label: "Working experience"
            },
            salary_range: {
              label: "Monthly salary"
            },
            region: {
              label: "Region"
            },
            employment_type: {
              label: "Employment type"
            },
            education_level: {
              label: "Required education level"
            },
            career_level: {
              label: "Career level"
            },
            date: {
              label: "Publish ad",
              placeholder: "Choose when to publish ad"
            },
            job_category: {
              label: "Job category"
            },
            type: {
              label: "Ad type"
            },
            is_foreign: {
              label: "Working abroad",
              description1: "Work location is abroad",
              description2: "Work location is in Slovenija"
            },
            has_trial_work: {
              label: "Trial period",
              description1: "Trial period required",
              description2: "Trial period not required"
            },
            trial_work_months: {
              label: "Trial period (no. months)"
            },
            city: {
              label: "Location",
              placeholder: "Work location"
            },
            contact_name: {
              label: "Contact person",
              placeholder: "Contact person"
            },
            contact_phone: {
              label: "Phone",
              placeholder: "Phone"
            },
            apply_email: {
              label: "E-mail for application",
              placeholder: "Enter application E-mail"
            },
            apply_url: {
              label: "WWW portal for applications",
              placeholder: "Enter WWW portal for applications"
            },
            offerings: {
              label: "Position added value",
              placeholder: "Enter position added value "
            },
            expectations: {
              label: "Requirements",
              placeholder: "Enter job requirements"
            },
            about_company: {
              label: "About the company",
              placeholder: "Short company description"
            },
            short_description: {
              label: "Ad summary",
              placeholder: "Enter short ad summary"
            },
            description: {
              label: "Job requirements and responsibilities",
              placeholder: "Enter main ad text"
            },
            types: {
              type_1: "Free",
              type_2: "Simple",
              type_3: "Basic",
              type_4: "Premium",
              type_5: "Executive",
              type_6: "Headhounter",
              type_7: "Student ad"
            },
            headlines: {
              work_place: "About the job",
              candidate: "Candidate traits",
              offerings: "What is company offering?",
              expectations: "Desired skills, experience and competences",
              company: "Company introduction",
              description: "Key challenges",
              contact: "Contact details"
            }
          },
          buttons: {
            cancel: "Cancel",
            confirm: "Save",
            publish: "Publish",
            lock: "Confirm ad",
            transfer: "Transfer"
          },
          tooltips: {
            transfer: "Transfer data from ADM. Save work after transfer."
          },
          url: "Link to your ad: ",
          notify: {
            save: {
              missing_name: "Job title required before saving ad.",
              success: "Ad saved.",
              error: "Error saving ad."
            }
          }
        }
      },
      candidates_tab: {
        candidates_list: {
          proposed: {
            headline: "Candidate approvals",
            name: "First and last name",
            confirm: "Confirm candidate",
            empty_list: {
              text1: "Candidate list not yet available.",
              text2: "Contact Competo for more info."
            },
            confirm_btn: "Confirm candidate",
            decline_btn: "Reject candidate",
            report: "Ψ report",
            report_consultant: "Consultant report",
            cv: "CV",
            education: "Education",
            experience: "Experience",
            competences: "Competences",
            langs: "Languages",
            adequacy: {
              "1": "Does not meet",
              "2": "Meets",
              "3": "Exceeds"
            },
            status_client: {
              "not-sent": "Not sent",
              sent: "Sent",
              confirmed: "Confirmed",
              selected: "Chosen",
              rejected: "Rejected"
            },
            show_competence_group: "Show competence group ",
            hide_competence_group: "Hide competence group ",
            show: "Show ",
            hide: "Hide ",
            testing_properties: "Reports",
            decline_popup: {
              title: "Candidate rejection",
              message: "Please enter reasons for rejecting candidate.",
              cancel: "Cancel",
              confirm: "Confirm"
            },
            notify: {
              confirm: {
                success: "Candidate is confirmed.",
                error: "Error confirming candidate."
              },
              decline: {
                success: "Candidate rejected.",
                error: "Error rejecting candidate."
              }
            },
            groups: {
              education: "Education",
              experience: "Job requirements",
              language: "Languages"
            }
          },
          selection: {
            headline: "List of candidates we want to meet",
            name: "Name and last name",
            confirm: "Choose candidate",
            empty_list: {
              text1: "Candidate list not yet available.",
              text2: "Contact Competo for more info."
            },
            confirm_btn: "Confirm candidate",
            decline_btn: "Reject candidate",
            report: "Ψ report",
            report_consultant: "Consultant report",
            cv: "CV",
            education: "Education",
            experience: "Experience",
            competences: "Competences",
            langs: "Languages",
            adequacy: {
              "1": "Does not meet",
              "2": "Meets",
              "3": "Exceeds"
            },
            status_client: {
              "not-sent": "Not sent",
              sent: "Sent",
              confirmed: "Confirmed",
              selected: "Chosen",
              rejected: "Rejected"
            },
            show_competence_group: "Show competence group ",
            hide_competence_group: "Hide competence group ",
            show: "Show ",
            hide: "Hide ",
            testing_properties: "Reports",
            decline_popup: {
              title: "Candidate rejection",
              message: "Please enter reasons for rejecting candidate.",
              cancel: "Cancel",
              confirm: "Proceed"
            },
            notify: {
              select: {
                success: "Candidate chosen.",
                error: "Error choosing candidate."
              },
              decline: {
                success: "Candidate rejected.",
                error: "Error rejecting candidate."
              }
            },
            groups: {
              education: "Education",
              experience: "Job requirements",
              language: "Languages"
            }
          }
        },
        candidates_expired: {
          text1: "Time limit for viewing candidates has expired.",
          text2: "Contact Competo for more info."
        }
      },
      micro_tab: {
        candidates_list: {
          headline: "Candidates included in Micro environment",
          name: "Name and last name",
          add_users: "Employee management",
          report: "Ψ report",
          education: "Education",
          experience: "Experience",
          competences: "Competences",
          langs: "Languages",
          adequacy: {
            "1": "Does not meet",
            "2": "Meets",
            "3": "Exceeds"
          },
          empty_list: {
            text1: "Candidate list is empty.",
            text2: "Add your employees/colleagues by openning Employee management."
          }
        }
      },
      candidates_drawer: {
        title: "Candidate",
        tabs: {
          overview: "Overview",
          competences: "Competences",
          opinion: "Consultant conclusions",
          summary: "Ψ summary",
          other: "Other"
        },
        overview: {
          basic: {
            headline: {
              candidate: "Candidate data",
              employee: "Employee data"
            },
            gender: {
              title: "Sex",
              male: "M",
              female: "F"
            },
            birthday: "Date of birth",
            education: "Education",
            phone: "Phone",
            email: "E-mail",
            redirect: {
              candidate: "Show candidate",
              employee: "Show employee"
            },
            position: "Department and position title",
            address: "Address",
            company: "Company",
            career: "Career level"
          },
          files: {
            report: {
              tooltip_download: "Download report",
              doesnt_exist: "Report not available"
            }
          },
          competences: {
            headline: "Project competences",
            content: {
              value_percentile: {
                text: "Percentile",
                title: "P"
              },
              value_denom: {
                text: "Mark",
                title: "O"
              },
              value: {
                text: "Raw number",
                title: "T"
              }
            },
            no_data: "Graph not available, not enough data to calculate competences."
          },
          intelligence: {
            headline: "General Emotional intelligence",
            rate: {
              "0": "data not available",
              "1": "very low",
              "2": "low",
              "3": "average",
              "4": "high",
              "5": "very high"
            }
          }
        },
        competences: {
          headline: "All competences",
          content: {
            value_percentile: {
              text: "Percentile",
              title: "P"
            },
            value_denom: {
              text: "Mark",
              title: "O"
            },
            value: {
              text: "Raw number",
              title: "T"
            }
          }
        },
        opinion: {
          opinion_headline: "Consultant conclusions for candidate for this project",
          no_opinion: "Consultant conclusions for this project have not been added.",
          other_opinion_headline: "Consultant conclusions from other projects",
          no_other_opinion: "Other projects do not have available consultant conclusions."
        },
        summary: {
          summary_headline: "Candidate summary for this project",
          no_summary: "No candidate summary available for this project.",
          other_summary_headline: "Candidate summaries from other projects",
          no_other_summary: "No candidate summaries available from other projects."
        }
      },
      add_candidates_drawer: {
        title: "Candidate list management",
        table: {
          search: "Search ...",
          name: "Name and last name",
          buttons: {
            add: "Add new employee"
          }
        },
        buttons: {
          save: "Add",
          cancel: "Cancel"
        }
      },
      add_candidates_popup: {
        headline: "Basic personal data",
        fields: {
          title: {
            label: "Name and last name",
            placeholder: "Enter employee name and last name"
          },
          email: {
            headline: "E-mail",
            headline_description: "Entering email enables sending test invitations and messages.",
            label: "E-mail",
            placeholder: "Enter e-mail"
          }
        },
        buttons: {
          save: "Save employee",
          cancel: "Cancel"
        },
        success_message: "Employee saved."
      }
    }
  }
}
